import http from 'utils/http';

// API call for fetching ticket custom field options
export const getCustomFieldOptions = async (filter = '') => {
  let res;
  try {
    res = await http.get(`/ticket/custom_fields`);
    if (filter) {
      res.data = res?.data?.custom_fields?.[filter] || [];
    } else {
      res.data = res?.data?.custom_fields || [];
    }
    return res?.data;
  } catch (e) {
    return e;
  }
};

/**
 * Create ticket API
 * @param {object} ticketInfo - contains ticket information.
 */
export const postTicket = async (ticketInfo) => {
  try {
    const res = await http.post(`/ticket/`, ticketInfo);
    return res;
  } catch (e) {
    return e;
  }
};

/**
 * Get robot list
 * @param {object} params - query params
 */
export const getRobotList = async (params) => {
  try {
    const res = await http.get(`/robot/`, { params });
    return res;
  } catch (e) {
    return e;
  }
};
