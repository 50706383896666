import { createSlice } from '@reduxjs/toolkit';

// Global app slice
export const appSlice = createSlice({
  name: 'app',
  reducers: {
    reset: () => {},
    clearStates: () => {}
  }
});

export const { reset, clearStates } = appSlice.actions;

export default appSlice.reducer;
