import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from 'utils/http';
import { setToken } from 'pages/auth/authSlice';
import { reset } from 'reducers/appSlice';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

/**
 * API call to fetch list of users organizations
 */
export const getOrganizations = createAsyncThunk(
  'organizations/list',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/user/organization`);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * API call to switch user's organization
 * and update new access token
 */
export const switchOrganization = createAsyncThunk(
  'organizations/switch',
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(`/user/switch_org`, payload);
      // set access token inside redux
      thunkAPI.dispatch(setToken(response?.data));
      // action to reset redux store except auth and organization state
      thunkAPI.dispatch(reset());
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Invalid organisation code',
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue(e);
    }
  }
);

/**
 * API call to create new organization
 */
export const postOrganization = createAsyncThunk(
  'organization/create',
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(`/organization/`, payload);
      response.organization_name = payload?.organization_name;
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * The organization slice containing list of organizations
 */
const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: {
    organizations: null,
    isOrganizationsLoading: false,
    isOrganizationSwitching: false,
    isOrganizationCreating: false
  },
  extraReducers: {
    [getOrganizations.pending]: (state) => {
      state.isOrganizationsLoading = true;
    },
    [getOrganizations.fulfilled]: (state, action) => {
      state.organizations = action.payload.data?.org_list;
      state.isOrganizationsLoading = false;
    },
    [getOrganizations.rejected]: (state) => {
      state.isOrganizationsLoading = false;
    },
    [switchOrganization.pending]: (state) => {
      state.isOrganizationSwitching = true;
    },
    [switchOrganization.fulfilled]: (state) => {
      state.isOrganizationSwitching = false;
    },
    [switchOrganization.rejected]: (state) => {
      state.isOrganizationSwitching = false;
    },
    [postOrganization.pending]: (state) => {
      state.isOrganizationCreating = true;
    },
    [postOrganization.fulfilled]: (state, action) => {
      state.isOrganizationCreating = false;
      state.organizations = [...state.organizations, { organization_id: action.payload.data?.organization_id,
        organization_name: action.payload.organization_name }];
    },
    [postOrganization.rejected]: (state) => {
      state.isOrganizationCreating = false;
    }
  }
});

export const selectOrganizations = (state) => (state.organizations?.organizations);
export const selectOrganizationsLoading = (state) => (state.organizations?.isOrganizationsLoading);
export const selectOrganizationSwitching = (state) => (state.organizations?.isOrganizationSwitching);
export const selectOrganizationCreating = (state) => (state.organizations?.isOrganizationCreating);

const { reducer } = organizationsSlice;
export default reducer;
