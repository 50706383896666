/**
 * The constants here are used throughout the signin
 * workflow to communicate the stages the user is in
 * this helps for different components to communicate
 * the necessary information without worring about the
 * underlying implementation
 */
export const NOT_LOGGED_IN = 0;
export const MFA_STAGE = 1;
export const LOGGED_IN = 2;
export const WRONG_CREDENTIALS = 3;
export const WRONG_PIN = 4;
export const SOMETHING_WENT_WRONG = 5;
