/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from 'utils/http';

import { enqueueSnackbar } from 'snackbar/snackbarSlice';

/**
 * A redux thunk action creator for fetching ticket details
 */
export const getTicket = createAsyncThunk(
  'ticket/getDetails',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/ticket/${params.ticketId}`);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// API call for updating ticket form
export const updateTicketForm = createAsyncThunk(
  'ticket/updateTicketForm',
  async (payload, thunkAPI) => {
    try {
      const response = await http.put(`/ticket/`, payload);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Ticket Updated Successfully',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for fetching comments
 */
export const getComments = createAsyncThunk(
  'ticket/getComments',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/ticket/comment`, { params });
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// Ticket slice
const ticketSlice = createSlice({
  name: 'ticket',
  initialState: {
    ticket: {
      ticket_id: '',
      description: '',
      created_at: '',
      property: {},
      escalation_savioke: false,
      operator: [],
      failure: [],
      resolution: [],
      problem: [],
      robot: '',
      status: '',
      subject: '',
      public: false,
      user: ''
    },
    isTicketLoading: false,
    isTicketUpdating: false,
    comments: [],
    isCommentsLoading: false,
    zendeskId: ''
  },
  reducers: {
    updateNewComment(state, action) {
      state.comments = [action.payload, ...state.comments];
    },
    resetTicketDetails(state) {
      state.ticket = {
        ticket_id: '',
        description: '',
        created_at: '',
        property: {},
        escalation_savioke: false,
        operator: [],
        failure: [],
        resolution: [],
        problem: [],
        robot: '',
        status: '',
        subject: '',
        public: false,
        user: ''
      };
    },
    resetComments(state) {
      state.comments = [];
    }
  },
  extraReducers: {
    [getTicket.pending]: (state) => {
      state.isTicketLoading = true;
    },
    [getTicket.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.isTicketLoading = false;
      state.ticket.ticket_id = data?.ticket_id;
      state.ticket.description = data?.description;
      state.ticket.created_at = data?.created_at;
      state.ticket.property = data?.property;
      state.ticket.created_at = data?.created_at;
      state.ticket.escalation_savioke = data?.escalation_savioke;
      state.ticket.operator = data?.operator;
      state.ticket.failure = data?.failure;
      state.ticket.resolution = data?.resolution;
      state.ticket.problem = data?.problem;
      state.ticket.robot = data?.robot;
      state.ticket.status = data?.status;
      state.ticket.subject = data?.subject;
      state.ticket.public = data?.public;
      state.ticket.user = data?.submitter;
      state.ticket.ticket_type = data?.ticket_type;
      state.ticket.image_data = data?.image_data;
    },
    [getTicket.rejected]: (state) => {
      state.isTicketLoading = false;
    },
    [updateTicketForm.pending]: (state) => {
      state.isTicketUpdating = true;
    },
    [updateTicketForm.fulfilled]: (state) => {
      state.isTicketUpdating = false;
    },
    [updateTicketForm.rejected]: (state) => {
      state.isTicketUpdating = false;
    },
    [getComments.pending]: (state) => {
      state.isCommentsLoading = true;
    },
    [getComments.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.comments = data.comments;
      state.isCommentsLoading = false;
    },
    [getComments.rejected]: (state) => {
      state.isCommentsLoading = false;
    }
  }
});

export const selectTicket = (state) => (state.ticket.ticket);
export const selectImageData = (state) => (state.ticket.imageData);
export const selectTicketLoading = (state) => (state.ticket.isTicketLoading);
export const selectTicketUpdating = (state) => (state.ticket.isTicketUpdating);
export const selectComments = (state) => (state.ticket.comments);
export const selectCommentsLoading = (state) => (state.ticket.isCommentsLoading);
export const { updateNewComment, resetTicketDetails, resetComments } = ticketSlice.actions;
const { reducer } = ticketSlice;
export default reducer;
