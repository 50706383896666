import { createSlice } from '@reduxjs/toolkit';

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: { notifications: [] },
  reducers: {
    enqueueSnackbar: {
      reducer: (state, { payload }) => {
        state.notifications.push(payload);
      },
      prepare(notification) {
        const payload = { ...notification, key: notification.key || (new Date().getTime() + Math.random()) };
        return { payload };
      }
    },
    closeSnackbar: {
      reducer: (state, action) => {
        const { payload } = action;
        state.notifications = state.notifications.map((notification) => {
          const shouldDismiss = payload.dismissAll || notification.key === payload.key;
          return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
        });
      },
      prepare: (key) => ({ payload: { key, dismissAll: !key } })
    },
    removeSnackbar: (state, { payload }) => {
      state.notifications = state.notifications.filter((notification) => notification.key !== payload);
    }
  }
});

export const {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar
} = snackbarSlice.actions;

export default snackbarSlice.reducer;
