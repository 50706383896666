import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { closeSnackbar, enqueueSnackbar } from 'snackbar/snackbarSlice';
import { getMissionMetrics, getTaskDetails } from './api';

export const exportMissionMetrics = createAsyncThunk(
  'missionMetrics/export',
  async (params, thunkAPI) => {
    try {
      const response = await getMissionMetrics(params);
      const taskId = response?.data?.task_id;
      if (taskId)thunkAPI.dispatch(pollTaskStatus(taskId));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// polls Task status every 2 second
export const pollTaskStatus = createAsyncThunk(
  'missionMetrics/pollStatus',
  async (taskId, { dispatch }) => {
    const snackbarKey = new Date().getTime() + Math.random();
    dispatch(enqueueSnackbar({
      message: 'Preparing mission export for download',
      isClearable: false,
      variant: 'success',
      key: snackbarKey,
      options: {
        autoHideDuration: null
      }
    }));
    const poll = async () => {
      try {
        const taskResult = await getTaskDetails(taskId);
        const taskStatus = taskResult?.data?.status;
        if (taskStatus === 'SUCCESS') {
          dispatch(setDownloadUrl(taskResult?.data?.s3_url));
          dispatch(closeSnackbar(snackbarKey));
          window.location.href = taskResult?.data?.s3_url;
        } else if (taskStatus === 'STARTED' || taskStatus === 'PENDING') {
          setTimeout(poll, 2000);
        } else if (taskStatus === 'FAILED') {
          dispatch(closeSnackbar(snackbarKey));
          dispatch(enqueueSnackbar({
            message: 'Download failed',
            isClearable: true,
            variant: 'error',
            key: new Date().getTime() + Math.random()
          }));
        }
        dispatch(setTaskStatus(taskResult?.data?.status));
      } catch (error) {
        dispatch(enqueueSnackbar({
          message: 'Download failed',
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        }));
      }
    };

    await poll();
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    taskStatus: '',
    downloadUrl: '',
    pollingTaskId: '',
    isLoading: false
  },
  reducers: {
    setTaskStatus: (state, action) => {
      state.taskStatus = action.payload;
    },
    setDownloadUrl: (state, action) => {
      state.downloadUrl = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportMissionMetrics.pending, (state) => {
        state.taskStatus = 'PENDING';
        state.isLoading = true;
      })
      .addCase(exportMissionMetrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pollingTaskId = action.payload?.data?.task_id;
      });
  }
});

export const selectTaskStatus = (state) => (state.dashboard.taskStatus);
export const selectIsLoading = (state) => (state.dashboard.isLoading);
export const { setTaskStatus, setDownloadUrl } = dashboardSlice.actions;
export default dashboardSlice.reducer;
