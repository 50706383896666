import axios from 'axios';
import store from 'app/store';

import { removeToken, resetAccessToken } from 'pages/auth/authSlice';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { setLoading, setProgress, setError } from 'components/Loaders/loadingSlice';

// Use a retryCount to track the number of times we have made a request
// This is done to prevent infinite Error 401 calls during response interceptor
let retryCount = 0;

const http = axios.create({
  baseURL: (typeof window._env_ === 'undefined') ? process.env.REACT_APP_API_URL : window._env_.REACT_APP_API_URL
});

// Request interceptor
http.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state?.auth?.currentUser?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    store.dispatch(setLoading(true));
    store.dispatch(setProgress(30));
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    store.dispatch(setProgress(0));
    return Promise.reject(error);
  }
);

// Response interceptor
// In case of unauthorized/401 error returns to login page
// V1
http.interceptors.response.use(
  (response) => {
    store.dispatch(setProgress(100));
    setTimeout(() => {
      // Add a timeout so that user can see progress to 100
      store.dispatch(setLoading(false));
    }, 500);
    return response;
  },
  async (error) => {
    // Note down the original request
    const originalRequest = error.config;

    // Temporary added status code 403 check because expired token error returns code 403 instead of 401
    // need to update it once, backend fixes to status code 401 for expired token
    if (error.response) {
      if ((error.response.status === 401 || error.response.status === 403)) {
        if (retryCount > 0) {
          store.dispatch(removeToken());
          store.dispatch(enqueueSnackbar({
            message: 'Session expired, Please login again',
            isClearable: true,
            variant: 'error',
            key: new Date().getTime() + Math.random()
          }));
          retryCount = 0;
        } else {
          const state = store.getState();
          const refreshToken = state?.auth?.currentUser?.refreshToken;
          retryCount += 1;
          if (refreshToken) {
            // Necessary to use refresh token value for access token, in order to call refresh endpoint
            store.dispatch(resetAccessToken(refreshToken));
            try {
              const msg = await http.post(`user/refresh`);
              console.log('refreshing token');
              // If successful refresh, set retryCount back to 0
              // This is because there can be multiple refresh sessions since
              // Refresh token takes a longer time to expire
              retryCount = 0;
              // After getting new access token from API call, set the new access token
              store.dispatch(resetAccessToken(msg.data.access_token));
              return http.request(originalRequest);
            } catch (err) {
              return Promise.reject(error);
            }
          } else {
            // In our initial release of Smart+, we did not save the user's refresh token
            // Thus, right now we have to handle the case when a refresh token does not exist
            // Currently, we can just direct the user back to the login page
            store.dispatch(removeToken());
            store.dispatch(enqueueSnackbar({
              message: 'Session expired, Please login again',
              isClearable: true,
              variant: 'error',
              key: new Date().getTime() + Math.random()
            }));
          }
          // Set retryCount back to 0 in case user tries to login again in same session
          retryCount = 0;
          store.dispatch(setLoading(false));
          store.dispatch(setProgress(0));
          return Promise.reject(error);
        }
      }
      store.dispatch(setLoading(false));
      store.dispatch(setProgress(0));
      return Promise.reject(error);
    }
  }
);

export default http;
