/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from 'app/store';
import { clearStates } from 'reducers/appSlice';
import http from 'utils/http';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

import { NOT_LOGGED_IN, MFA_STAGE, LOGGED_IN, WRONG_CREDENTIALS, SOMETHING_WENT_WRONG, WRONG_PIN } from './constants';

/**
 * A redux thunk action creator that will register a user with new organization
*/

export const register = createAsyncThunk(
  'auth/registration',
  async (params, thunkAPI) => {
    try {
      const response = await http.post(`user/registration`, params);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e?.response?.data?.message);
    }
  }
);

/**
 * A redux thunk action creator that will create a user and
 * adds to an existing organization
 */

export const createUser = createAsyncThunk(
  'auth/createUser',
  async (params, thunkAPI) => {
    try {
      const response = await http.post(`user/`, params);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response);
    }
  }
);

/**
 * A redux thunk action creator that will take in a username
 * and password and make the appropriate api call, set the
 * jwt token if valid credentials are entered and then return
 * the decoded user profile from jwt. If the response says
 * invalid credentials, then just return an object that says
 * invalid and if something else goes wrong just return an
 * error message.
 */
export const login = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const msg = await http.post(`user/login`, {
        username,
        password
      }).then((res) => {
        if (res.data.access_token) {
          const profile = jwt_decode(res.data.access_token);
          if (profile.user_claims.authorized) {
            return { ...res.data, loginStatus: LOGGED_IN };
          }
          return { ...res.data, loginStatus: MFA_STAGE };
        }
        return { ...res.data, loginStatus: NOT_LOGGED_IN, error: WRONG_CREDENTIALS };
      });
      return msg;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for verifying otp. It will take in
 * the otp and make the appropriate api call with the appropriate
 * headers and if valid set the new jwt and return the
 * decoded profile from jwt, if invalid just return an object with
 * invalid.
 */
export const verifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async (otp, thunkAPI) => {
    try {
      const response = await http.post(`/user/mfa/verify`, {
        otp
      })
        .then((res) => {
          if (res.data.access_token) {
            return { ...res.data, loginStatus: LOGGED_IN };
          }
          return { ...res.data, loginStatus: MFA_STAGE, error: WRONG_PIN };
        });
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for retrieving reset link via email when forget password.
 */
export const forgetPassword = createAsyncThunk(
  'auth/forgetPassword',
  async (email, thunkAPI) => {
    try {
      const response = await http.post(`/user/password/forgot`, {
        email
      });
      thunkAPI.dispatch(enqueueSnackbar({
        message: `A link to reset password has been sent to ${email}`,
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response.data;
    } catch (err) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: `Failed to send password reset link`,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator for user logout.
 */
export const logout = createAsyncThunk(
  'auth/logout',
  async (params, thunkAPI) => {
    try {
      const msg = await http.delete(`user/logout`).then((res) => {
        if (res.status === 200) {
          store.dispatch(removeToken());
          store.dispatch(clearStates());
        }
      });
      return msg;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`/user/${params.username}`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (params, thunkAPI) => {
    try {
      const response = await http.put(`/user/`, params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Profile Updated Successfully',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

/**
 * A redux thunk action creator that will add an existing
 * user to an existing organization
*/

export const addUserToOrganization = createAsyncThunk(
  'organization/user',
  async (params, thunkAPI) => {
    try {
      const response = await http.post(`organization/user`, params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: response?.data?.message,
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const verifyEmailToken = createAsyncThunk(
  'user/email/verification',
  async (params, thunkAPI) => {
    try {
      const response = await http.post(`user/email/verification`, params);
      return response;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: e?.response?.data?.message,
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return thunkAPI.rejectWithValue();
    }
  }
);

// intial state for the auth
const initialState = {
  loginStatus: NOT_LOGGED_IN,
  error: '',
  currentUser: {
    accessToken: null,
    refreshToken: null,
    userDetails: {
      userId: null,
      user: null,
      organizationId: null,
      organizationCode: null,
      permissions: [],
      role: []
    },
    userProfile: null,
    getUserProfileLoading: false
  }
};

/**
 * The slice that involves authentication, to help track of log in status
 * (whether the user is logged in or in the two factor authentication (tfa)
 * stage etc).
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeToken: (state) => {
      state.loginStatus = initialState.loginStatus;
      state.error = initialState.error;
      state.currentUser = initialState.currentUser;
    },
    setToken: (state, action) => {
      const { user_id, user, organization_id, organization_code, permissions, role } = jwt_decode(
        action.payload.access_token
      ).user_claims;
      state.loginStatus = LOGGED_IN;
      state.currentUser.accessToken = action.payload.access_token;
      state.currentUser.refreshToken = action.payload.refresh_token;
      state.currentUser.userDetails = {
        userId: user_id,
        user,
        organizationId: organization_id,
        organizationCode: organization_code,
        permissions,
        role
      };
    },
    // Used when using the refresh token to get a new access token
    resetAccessToken: (state, action) => {
      state.currentUser.accessToken = action.payload;
    }
  },
  extraReducers: {
    [verifyOtp.fulfilled]: (state, action) => {
      if (action.payload.loginStatus === LOGGED_IN) {
        const { user_id, user, organization_id, organization_code, permissions, role } = jwt_decode(
          action.payload.access_token
        ).user_claims;
        state.currentUser.accessToken = action.payload.access_token;
        state.currentUser.refreshToken = action.payload.refresh_token;
        state.currentUser.userDetails = {
          userId: user_id,
          user,
          organizationId: organization_id,
          organizationCode: organization_code,
          permissions,
          role
        };
      }
      state.loginStatus = action.payload.loginStatus;
      state.error = action.payload.error;
    },
    [verifyOtp.rejected]: (state) => {
      state.loginStatus = MFA_STAGE;
      state.error = SOMETHING_WENT_WRONG;
    },
    [login.fulfilled]: (state, action) => {
      if (action.payload.loginStatus === LOGGED_IN) {
        const {
          user_id,
          user,
          organization_id,
          organization_code,
          permissions,
          role
        } = jwt_decode(action.payload.access_token).user_claims;
        state.currentUser.accessToken = action.payload.access_token;
        state.currentUser.refreshToken = action.payload.refresh_token;
        state.currentUser.userDetails = {
          userId: user_id,
          user,
          organizationId: organization_id,
          organizationCode: organization_code,
          permissions,
          role
        };
      } else if (action.payload.loginStatus === MFA_STAGE) {
        state.currentUser.accessToken = action.payload.access_token;
        state.loginStatus = action.payload.loginStatus;
      }
      state.loginStatus = action.payload.loginStatus;
      state.error = action.payload.error;
    },
    [login.rejected]: (state) => {
      state.loginStatus = NOT_LOGGED_IN;
      state.error = SOMETHING_WENT_WRONG;
    },
    [getUserProfile.fulfilled]: (state, action) => {
      state.currentUser.userProfile = action.payload.data;
      state.currentUser.getUserProfileLoading = false;
    },
    [getUserProfile.pending]: (state) => {
      state.currentUser.getUserProfileLoading = true;
    },
    [getUserProfile.rejected]: (state) => {
      state.currentUser.getUserProfileLoading = false;
    },
    [updateUserProfile.fulfilled]: (state, action) => {
      state.currentUser.userProfile.first_name = action.payload.data.first_name;
      state.currentUser.userProfile.last_name = action.payload.data.last_name;
      state.currentUser.userProfile.phone_number = action.payload.data.phone_number;
    }
  }
});

export const { removeToken, setToken, resetAccessToken } = authSlice.actions;

export const selectAccessToken = (state) => (state?.auth?.currentUser?.accessToken);
export const selectRefreshToken = (state) => (state?.auth?.currentUser?.refreshToken);
export const selectUserDetails = (state) => (state?.auth?.currentUser?.userDetails);
export const selectUserProfile = (state) => (state?.auth?.currentUser?.userProfile);
export const selectUserProfileLoading = (state) => (state?.auth?.currentUser.getUserProfileLoading);
export const selectUserLoginStatus = (state) => (state?.auth?.loginStatus);

const { reducer } = authSlice;
export default reducer;
