import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: !navigator.onLine };

const offlineSlice = createSlice({
  name: 'offline',
  initialState,
  reducers: {
    setOffline(state, action) {
      state.value = action.payload;
    }
  }
});
export const selectOffline = (state) => (state.offline.value);

export const { setOffline } = offlineSlice.actions;
export default offlineSlice.reducer;
