/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import http from 'utils/http';

const initialState = { device: null, settings: null, isDeviceLoading: false, isSettingsLoading: false };
export const registerDevice = createAsyncThunk(
  'push/registerDevice',
  async (params, thunkAPI) => {
    try {
      const response = await http.post(`push_notification/`, params);
      const { data } = response;
      const settings = params.settings || {};
      // Dispatch updateDevice with the necessary parameters
      thunkAPI.dispatch(updateDevice({ device_id: data.device_id, settings }));
      localStorage.setItem(`${params.userId}/push`,
        JSON.stringify({
          ...response.data
        }));
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateDevice = createAsyncThunk(
  'push/updateDevice',
  async (params, thunkAPI) => {
    const payload = {
      'va.positive_detection': false,
      'fm.remote_tagging': false,
      'robot.mislocalisation': false,
      'robot.idling': false,
      'robot.bumper_lockout': false,
      'robot.safety_lockout': false,
      'robot.robot_error': false,
      'robot.health_error': false,
      'mission.complete': false,
      'status.battery_critical': false,
      'status.charging_complete': false,
      'e_stop.activation': false,
      'call.incoming_call': false,
      'escalation_delegation.alert_escalation': false,
      'escalation_delegation.task_delegation': false,
      ...params.settings
    };
    try {
      const response = await http.put(`push_notification/${params.device_id}/settings`, payload);
      return { response, settings: payload };
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getDeviceSettings = createAsyncThunk(
  'push/getDeviceSettings',
  async (params, thunkAPI) => {
    try {
      const response = await http.get(`push_notification/${params.device_id}/settings`);
      return response;
    } catch (err) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const pushSlice = createSlice({
  name: 'push',
  initialState,
  reducers: {
    setDevice(state, action) {
      state.device = action.payload;
    }
  },
  extraReducers: {
    [registerDevice.fulfilled]: (state, action) => {
      state.device = action.payload;
      state.isDeviceLoading = false;
    },
    [registerDevice.rejected]: (state) => {
      state.device = null;
      state.isDeviceLoading = false;
    },
    [registerDevice.pending]: (state) => {
      state.device = null;
      state.isDeviceLoading = true;
    },
    [updateDevice.fulfilled]: (state, action) => {
      state.settings = action.payload.settings;
      state.isSettingsLoading = false;
    },
    [updateDevice.rejected]: (state) => {
      state.settings = null;
      state.isSettingsLoading = false;
    },
    [updateDevice.pending]: (state) => {
      state.settings = null;
      state.isSettingsLoading = true;
    },
    [getDeviceSettings.fulfilled]: (state, action) => {
      state.settings = action.payload.data;
      state.isSettingsLoading = false;
    },
    [getDeviceSettings.rejected]: (state) => {
      state.settings = null;
      state.isSettingsLoading = false;
    },
    [getDeviceSettings.pending]: (state) => {
      state.settings = null;
      state.isSettingsLoading = true;
    }
  }
});
export const selectDevice = (state) => state.push.device;
export const selectSettings = (state) => state.push.settings;
export const selectIsSettingsLoading = (state) => state.push.isSettingsLoading;

export const { setDevice } = pushSlice.actions;
export default pushSlice.reducer;
