import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: false };

const fullscreenSlice = createSlice({
  name: 'fullscreen',
  initialState,
  reducers: {
    setFullScreen(state, action) {
      state.value = action.payload;
    }
  }
});
export const selectFullscreen = (state) => (state.fullscreen.value);

export const { setFullScreen } = fullscreenSlice.actions;
export default fullscreenSlice.reducer;
