import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { ReactComponent as DangerIcon } from '../assets/danger_circle.svg';

const RefreshButton = styled(Button)`
margin-top: 1rem;
width: 296px;
height: 5.33%;
`;
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  static handleRefresh = () => {
    window.location.reload();
  };

  render() {
    const { theme } = this.props;
    if (this.state.hasError) {
      return (
        <Box
          backgroundColor={theme.palette.background.secondary}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          width="100%"
          height="100vh"
        >
          <DangerIcon
            height="54px"
            width="54px"
            fill="none"
            stroke="white"
            style={{ marginBottom: '20px' }}
          />
          <Typography
            variant="h2"
            style={{
              marginBottom: '32px'
            }}
            maxWidth="78.93%"
          >
            Oops! Something went wrong.
          </Typography>
          <Typography
            variant="title1Semibold"
            color={theme.palette.text.tooltipText2}
            maxWidth="78.93%"
          >
            There was a problem processing the request. Please try again.
          </Typography>
          <Typography
            variant="body2Semibold"
            color={theme.palette.text.secondary}
            style={{
              marginBottom: '32px'
            }}
          >
            <b>
              <br />
              Error:
              &nbsp;
              {this.state.error ? this.state.error.message : 'An error occurred.'}
            </b>
          </Typography>
          <RefreshButton
            variant="contained"
            type="button"
            onClick={ErrorBoundary.handleRefresh}
          >
            Refresh
          </RefreshButton>
        </Box>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      background: PropTypes.shape({
        secondary: PropTypes.string.isRequired
      }).isRequired,
      text: PropTypes.shape({
        tooltipText2: PropTypes.string.isRequired,
        secondary: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

ErrorBoundary.defaultProps = {
  children: null
};

export default ErrorBoundary;
