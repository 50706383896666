/**
 * Checks if a JWT token is expired.
 * @param {string} token - The JWT token to check.
 * @returns {boolean} - True if the token is expired, false otherwise.
 */
const isJWTExpired = (token) => {
  try {
    const header = token.split('.')[0];
    const decodedHeader = JSON.parse(atob(header));
    const tokenExpiration = decodedHeader.exp;
    const currentTime = Math.floor(Date.now() / 1000);

    return currentTime >= tokenExpiration;
  } catch (error) {
    return true;
  }
}
/**
 * Detect's user device type and the os they are using
 * @returns { deviceType, os }
 */
const detectDeviceAndOS = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let deviceType = 'unknown';
  let os = 'unknown';

  if (userAgent.match(/android/i)) {
    deviceType = 'mobile';
    os = 'Android';
  } else if (userAgent.match(/webos/i)) {
    deviceType = 'tablet';
    os = 'WebOS';
  } else if (userAgent.match(/iphone|ipad|ipod/i)) {
    deviceType = 'mobile';
    os = 'iOS';
  } else if (userAgent.match(/windows phone/i)) {
    deviceType = 'mobile';
    os = 'Windows Phone';
  } else if (userAgent.match(/ipad/i)) {
    deviceType = 'tablet';
    os = 'iOS';
  } else if (userAgent.match(/windows nt/i) && userAgent.match(/touch/i)) {
    deviceType = 'tablet';
    os = 'Windows';
  } else if (userAgent.match(/kindle/i) || userAgent.match(/silk/i)) {
    deviceType = 'tablet';
    os = 'Kindle';
  } else if (userAgent.match(/playbook/i)) {
    deviceType = 'tablet';
    os = 'BlackBerry';
  } else if (userAgent.match(/macintosh/i) && 'ontouchend' in document) {
    deviceType = 'tablet';
    os = 'Macintosh';
  } else if (userAgent.match(/macintosh/i)) {
    deviceType = 'desktop';
    os = 'Macintosh';
  } else if (userAgent.match(/windows nt/i)) {
    deviceType = 'desktop';
    os = 'Windows';
  } else if (userAgent.match(/linux/i)) {
    deviceType = 'desktop';
    os = 'Linux';
  } else if (userAgent.match(/cros/i)) {
    deviceType = 'desktop';
    os = 'Chrome OS';
  }

  return { deviceType, os };
};

export { detectDeviceAndOS, isJWTExpired };
