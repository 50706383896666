/* Download event logs for a robot */
import http from 'utils/http';
import store from 'app/store';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';

// eslint-disable-next-line import/prefer-default-export
export const getMissionMetrics = async (params) => {
  let res;
  try {
    res = await http.get(`/mission-instance/export`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

export const getTaskDetails = async (taskId) => {
  let res;
  try {
    res = await http.get(`/celery/task/${taskId}`);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
