import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    lightTheme: false,
    autoTheme: true
  },
  reducers: {
    toggleTheme: (state, action) => {
      if (action.payload !== undefined && state.autoTheme) state.lightTheme = action.payload;
      else state.lightTheme = !state.lightTheme;
    },
    toggleAutoTheme: (state) => {
      state.autoTheme = !state.autoTheme;
      if (state.autoTheme) {
        const systemLightTheme = (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches);
        state.lightTheme = systemLightTheme;
      }
    }
  }
});

export const { toggleTheme, toggleAutoTheme } = themeSlice.actions;

export const selectTheme = (state) => (state?.theme?.lightTheme);
export const selectAutoTheme = (state) => (state?.theme?.autoTheme);

export default themeSlice.reducer;
