import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function GlobalLoader({ currentProgress }) {
  return (
    <Box sx={{ width: '100%' }}>
      {/* Specify height so that it is more visible */}
      <LinearProgress
        variant="determinate"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
        color="secondary"
        value={currentProgress}
      />
    </Box>
  );
}

export default GlobalLoader;
