import { Box } from '@mui/system';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/system';

import { ReactComponent as SMART_PLUS } from 'assets/smart_plus.svg';
import { ReactComponent as EXPIRED_DARK } from 'assets/expired_dark.svg';
import { ReactComponent as EXPIRED_LIGHT } from 'assets/expired_light.svg';
import { Typography } from '@mui/material';

const Header = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center
    width: 100%;
    padding: 36px;
    margin-bottom: 60px;
`;

export default function InvitationExpired() {
    const theme = useTheme();
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            alignItems={"center"}>
            <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"center"}
                padding="16px"
                maxWidth={"520px"}
            >
                <Header>
                    <SMART_PLUS
                        fill="#219EBC"
                    />
                </Header>
                <Box>
                    {theme.palette.mode === 'dark' ? <EXPIRED_DARK
                        style={{
                            width: "100%"
                        }}
                    /> : <EXPIRED_LIGHT
                        style={{
                            width: "100%"
                        }}
                    />}
                    <Box
                        display={"flex"}
                        flexDirection="column"
                        gap="16px"
                        alignItems={"center"}
                        marginTop="32px"
                    >
                        <Typography
                            variant="h2"
                            textAlign={"center"}
                        >
                            Invitation Expired
                        </Typography>
                        <Typography
                            variant="title1Semibold"
                            textAlign={"center"}
                            color={theme.palette.text.secondary}
                            marginTop="16px"
                        >
                            We're sorry, but your invitation link has expired.
                        </Typography>
                        <Typography
                            variant="body2Semibold"
                            textAlign={"center"}
                            color={theme.palette.text.secondary}
                            style={{
                                marginBottom: '32px'
                            }}
                        >
                            Invitation links are only active for a limited time to ensure security. It's possible that the link has surpassed this period, or the invitation has been revoked. Please check with the person who invited you to Smart+.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

