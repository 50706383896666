// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import snackbar from 'snackbar/snackbarSlice';
import fullscreen from 'fullscreen/fullscreenSlice';
import tickets from 'pages/tickets/ticketsSlice';
import ticket from 'pages/ticket/ticketSlice';
import robotops from 'pages/robotops/robotopsSlice';
import dashboard from 'pages/dashboard/dashboardSlice';
import robot from 'pages/robotpage/robotSlice';
import organizations from 'layouts/header/organization/organizationsSlice';
import auth from 'pages/auth/authSlice';
import offline from 'reducers/offlineSlice';
import push from 'reducers/pushSlice';
import theme from 'reducers/themeSlice';
import loading from 'components/Loaders/loadingSlice';
import alerts from 'pages/alerts/alertsSlice';
import alert from 'pages/alert/alertSlice';
import tour from 'product-tour/tourSlice';

const appReducer = combineReducers({ auth,
  snackbar,
  fullscreen,
  offline,
  organizations,
  dashboard,
  robotops,
  robot,
  push,
  tickets,
  ticket,
  alert,
  alerts,
  theme,
  tour,
  loading });

// returns single reducer by combining all reducers
const rootReducer = (state, action) => {
  // Clear all data in redux store to initial except auth, organizations, theme
  if (action.type === 'app/reset') {
    state = { auth: state?.auth, organizations: state?.organizations, theme: state?.theme };
  }
  if (action.type === 'app/clearStates') { state = { theme: state?.theme }; }

  return appReducer(state, action);
};
export default rootReducer;
