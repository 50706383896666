import { configureStore } from '@reduxjs/toolkit';

import rootReducer from 'reducers';
import { loadState, saveState } from 'localStorage';

/**
 * The redux store has been created with these options:
 * The rootReducer function returns combining all reducers with combineReducers()
 * redux-thunk and redux-logger were added as middleware by default
 * The Redux DevTools Extension is disabled for production
 * Preloaded state which persists the auth state
 */
const configureAppStore = () => {
  const persistedState = loadState() || {};
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: { ...persistedState },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: false
    })
  });

  store.subscribe(() => { saveState({ auth: store.getState().auth, theme: store.getState().theme }); });

  return store;
};

export default configureAppStore();
