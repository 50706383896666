import http from 'utils/http';
import store from 'app/store';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';


/* Fetch single robot by robot code and org code */
export const fetchRobot = async (params) => {
  try {
    const res = await http.get('/robot/organization_code', { params });
    return res;
  } catch (e) {
    return e;
  }
};


/* Fetch logs of a robot */
export const fetchRobotLogs = async (params) => {
  let res;
  try {
    res = await http.get(`/event_log/`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/* Download event logs for a robot */
export const downloadRobotLogs = async (params) => {
  let res;
  try {
    res = await http.get(`event_log/download`, { params });
    store.dispatch(enqueueSnackbar({
      message: 'Exported successfully. The file will be sent to your email within 10 minutes.',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/**
 * API call to get schedules
 * @param params {Object} - query parameters
 */
export const getSchedules = async (params) => {
  try {
    const res = await http.get(`schedule/`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to get missions
 * @param params {Object} - parameters for query
 */
export const getMissions = async (params) => {
  try {
    const res = await http.get(`mission/`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to get mission instsances
 */
export const getMissionInstances = async (params) => {
  try {
    const res = await http.get('mission-instance/', { params });
    return res.data;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to create mission
 * @param payload {Object} - body for mission creation
 */
export const postMission = async (payload) => {
  try {
    const res = await http.post(`mission/`, { ...payload });
    store.dispatch(enqueueSnackbar({
      message: 'Mission created successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to update mission
 *  @param missionId {string} - mission id to update mission
 * @param payload {Object} - body for mission updation
 */
export const putMission = async (missionId, payload) => {
  try {
    const res = await http.put(`mission/`, { ...payload, mission_id: missionId });
    store.dispatch(enqueueSnackbar({
      message: 'Mission updated successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to delete a mission
 * @param missionId {string} - mission id to delete mission
 */
export const deleteMission = async (missionId, missionName) => {
  try {
    const res = await http.delete(`mission/${missionId}`);
    store.dispatch(enqueueSnackbar({
      message: `Mission ${missionName} has been deleted successfully`,
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: 'Error in deleting mission',
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/**
 * API call to create schedules
 * @param params {Object} - query parameters
 */

export const createSchedule = async (params) => {
  try {
    const res = await http.post(`schedule/`, params);
    store.dispatch(enqueueSnackbar({
      message: 'Schedule created successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/**
 * API call to update a schedules
 * @param {Object} params - query parameters
 */

export const updateSchedule = async (params) => {
  try {
    const res = await http.put(`schedule/`, params);
    store.dispatch(enqueueSnackbar({
      message: 'Schedule updated successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/**
 * API call to get details of one schedule
 * @param {String} scheduleId - id of schedule
 */

export const getScheduleDetails = async (scheduleId) => {
  try {
    const res = await http.get(`schedule/metadata/${scheduleId}`);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/**
 * API call to delete a schedule
 * @param {Object} payload - payload for deleting schedule
 */

export const deleteSchedule = async (payload) => {
  try {
    const res = await http.delete(`schedule/${payload?.schedule_id}`, { data: payload });
    store.dispatch(enqueueSnackbar({
      message: 'Schedule deleted successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};

/**
 * API call to get waypoints
 * @param params {Object} - parameters for query
 */
export const getWaypoints = async (params) => {
  try {
    const res = await http.get(`robot/waypoint`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};

/* Fetch robot properties for a single robot by robot_id */
export const fetchRobotProperties = async (robotID) => {
  try {
    const params = { filter: { robot_id: robotID }, fields: 'properties' };
    const res = await http.get(`/robot/`, { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: 'Unable to fetch robot properties',
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};


/* Update robot properties for a single robot */
export const updateRobotProperties = async (params, operationContext) => {
  try {
    const res = await http.put(`/robot/`, params);
    let message = 'Properties updated successfully';
    if (operationContext === 'delete') {
      message = 'Property deleted successfully';
    }
    store.dispatch(enqueueSnackbar({
      message: message,
      isClearable: true,
      variant: 'success',
    }));
    return { success: true, response: res };

  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: `Error updating properties`,
      isClearable: true,
      variant: 'error',
    }));
    return { success: false, error: e };
  }
};


/* Over-write Robot Details */
/* Change Notifcation Status of a user */
export const SetPilotConfig = async (payload) => {
  try {
    const res = await http.put(`/robot/`, payload);
    if (res.status !== 200) {
      store.dispatch(enqueueSnackbar({
        message: 'Failed to update settings',
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return null;
    }
    store.dispatch(enqueueSnackbar({
      message: 'Settings updated successfully',
      isClearable: true,
      variant: 'success',
      key: new Date().getTime() + Math.random()
    }));
    return res;
  } catch (e) {
    return null;
  }
};

/**
 * API to get robot health metric data
 * @param params
 * @returns robot health metric data
 */
export const getRobotHealth = async (params) => {
  try {
    const res = await http.get('/robot/health', { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return null;
  }
};

/**
 * API to get robot historical health metric data
 * @param params
 * @returns robot historical health metric data
 */
export const getRobotHealthHistory = async (params, cancelToken) => {
  try {
    const res = await http.get('/robot/health-history', { params, cancelToken });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return null;
  }
};

/**
 * API to export robot historical health metric data
 * @param params
 * @returns celery task_id
 */
export const exportRobotHealthHistory = async (params) => {
  try {
    const res = await http.get('/robot/health-history-export', { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return null;
  }
};

/**
 * API to get task details
 */
export const getTaskDetails = async (taskId) => {
  let res;
  try {
    res = await http.get(`/celery/task/${taskId}`);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return e;
  }
};
/**
 * API call to get robot stream URL
 * @param robotId {string} - robot id
 */
export const getRobotHLSURL = async (robotId) => {
  try {
    const res = await http.get(`robot/${robotId}/camera`);
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
  }
  return null;
};


/**
 * API to get robot historical health metric data
 * @param params
 * @returns robot historical health metric data
 */
 export const getRobotHealthScalar = async (params) => {
  try {
    const res = await http.get('/robot/health/query/scalars', { params });
    return res;
  } catch (e) {
    store.dispatch(enqueueSnackbar({
      message: e?.response?.data?.message,
      isClearable: true,
      variant: 'error',
      key: new Date().getTime() + Math.random()
    }));
    return null;
  }
};
