/* eslint-disable react/no-unescaped-entities */
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectOffline } from 'reducers/offlineSlice';

import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { enqueueSnackbar, closeSnackbar } from 'snackbar/snackbarSlice';
import offlineLogo from '../assets/offline.png';

const TryAgainButton = styled(Button)`
margin-top: 1rem;
width: 296px;
height: 5.33%;
`;

const OfflineFallback = ({ children, showMessage }) => {
  const offline = useSelector(selectOffline);
  const dispatch = useDispatch();
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );
  const isTabView = useMediaQuery(
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    const snackbarKey = new Date().getTime() + Math.random();
    if (offline && showMessage) {
      dispatch(enqueueSnackbar({
        message: 'You are currently offline!',
        isClearable: false,
        variant: 'warning',
        key: snackbarKey,
        options: {
          autoHideDuration: null
        }
      }));
    }
    return () => {
      dispatch(closeSnackbar(snackbarKey));
    };
  }, [offline, dispatch, showMessage]);

  const handleClick = () => {
    if (navigator.onLine) {
      window.location.reload(true);
    } else {
      dispatch(enqueueSnackbar({
        message: 'Please try to connect again!',
        isClearable: true,
        variant: 'warning',
        key: new Date().getTime() + Math.random()
      }));
    }
  };

  return (
    offline && !showMessage ? (
      <Box
        backgroundColor={theme.palette.background.secondary}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        width="100%"
        height="100%"
      >
        <div>
          <img
            src={offlineLogo}
            alt="Logo"
            width="47.99px"
            style={{ marginBottom: '44.32px' }}
          />
        </div>

        <Typography
          variant="h2"
          style={{ marginBottom: '32px' }}
          maxWidth="78.93%"
        >
          Oops! You're&nbsp;Offline
        </Typography>

        <Typography
          variant="title1Semibold"
          color={theme.palette.text.tooltipText2}
          style={{ marginBottom: (isMobileScreen || isTabView) ? '8px' : '16px' }}
          maxWidth="78.93%"
        >
          It seems you're not connected to the internet.
        </Typography>

        <Typography
          variant="body2Semibold"
          color={theme.palette.text.secondary}
          width={(isMobileScreen || isTabView) ? '78.93%' : '30.21%'}
          style={{ marginBottom: '32px' }}
        >
          {isMobileScreen ? (
            <>
              Please check your internet connection and try again.
              If you're still experiencing issues, feel free to reach out to our
              customer support team for assistance.
            </>
          ) : (
            <>
              Please check your internet connection and try again.
              <br />
              If you're still experiencing issues, feel free to reach out to our
              <br />
              customer support team for assistance.
            </>
          )}

        </Typography>

        <TryAgainButton
          variant="contained"
          onClick={handleClick}
          // onClick={() => window.location.reload(true)}
        >
          Try Again
        </TryAgainButton>
      </Box>) : children
  );
};

OfflineFallback.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default OfflineFallback;
