import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
    error: null,
    progress: 0
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
      if (action.payload === true) {
        state.progress = 0;
      }
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setProgress, setError } = loaderSlice.actions;

export const selectLoading = (state) => (state.loading.loading);
export const selectProgress = (state) => (state.loading.progress);

export default loaderSlice.reducer;
