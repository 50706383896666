import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function CircularLoader() {
  return (
    <Box display="flex" justifyContent="center" width="100%" height="100vh" alignItems="center">
      <CircularProgress color="secondary" />
    </Box>
  );
}

export default CircularLoader;
