/**
 * Compares the name of robots using normal string comparison
 *
 * @param {object} robot1 - An object containing the name of robot in string format
 * @param {object} robot2 - An object containing the name of robot in string format
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal or lesser order than robot2
 */
const nameComparator = (robot1, robot2) => robot1.robot_name.localeCompare(robot2.robot_name);

/**
 * Compares the status ('Online', 'Offline', null) of the robots, with
 * 'Online' > 'Offline' > null
 *
 * @param {object} robot1 - An object containing the status of robot in string format
 * @param {object} robot2 - An object containing the status of robot in string format
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal, or lesser order than robot2
 */
const statusComparator = (robot1, robot2) => {
  if (robot1.status === robot2.status) {
    return 0;
  } if ((robot1.status === 'Online' && robot2.status === 'Offline')
    || ((robot1.status === 'Offline' && robot1.last_heartbeat_at !== null)
        && (robot2.status === 'Offline' && robot2.last_heartbeat_at === null))) {
    return 1;
  }
  return -1;
};

/**
 * Compares the autonomous staus (true, false, null) of the robots with
 * true > false > null
 *
 * @param {object} robot1 - An object containing the autonomous state of robot in bool form
 * @param {object} robot2 - An object containing the autonomous state of robot in bool form
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal, or lesser order than robot2
 */
const autonomousComparator = (robot1, robot2) => {
  if (robot1.autonomous === robot2.autonomous) {
    return 0;
  } if (robot1.autonomous !== null && (!robot2.autonomous || robot2.autonomous === null)) {
    return 1;
  }
  return -1;
};

/**
 * Compares the battery level using normal integer comparison, if data is not
 * available it is automatically the lowest order
 *
 * @param {object} battery1 - An object containing the battery level of the robot
 * @param {object} battery2 - An object containing the battery level of the robot
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal, or lesser order than robot2
 */
const batteryComparator = (battery1, battery2) => {
  const batteryLevel1 = battery1.batteryLevel
    ? Number.parseInt(battery1.batteryLevel, 10) : -1;
  const batteryLevel2 = battery2.batteryLevel
    ? Number.parseInt(battery2.batteryLevel, 10) : -1;

  if (batteryLevel1 > batteryLevel2) {
    return 1;
  } if (batteryLevel1 < batteryLevel2) {
    return -1;
  }
  return 0;
};

/**
 * TODO once the values becomes available
 */
const userComparator = null;

/**
 * Compares the estop values (true, false, null) of the robots with
 * true > false > null
 *
 * @param {object} robot1 - An object containing the estop state of the robot in bool form
 * @param {object} robot2 - An object containing the estop state of the robot in bool form
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal, or lesser order than robot2
 */
const estopComparator = (robot1, robot2) => {
  if (robot1.estop === robot2.estop) {
    return 0;
  } if (robot1.estop !== null && (robot2.estop === null || !robot2.estop)) {
    return 1;
  }
  return -1;
};

/**
 * Compares the localisation values (true, false, null) of the robots with
 * true > false > null
 *
 * @param {object} robot1 - An object containing the localisation state of the robot in bool form
 * @param {object} robot2 - An object containing the localisation state of the robot in bool form
 * @returns 1, 0, -1 depending on if robot1 has a greater, equal, or lesser order than robot2
 */
const localisationComparator = (robot1, robot2) => {
  if (robot1.localisation === robot2.localisation) {
    return 0;
  } if (robot1.localisation !== null && (robot2.localisation === null || !robot2.localisation)) {
    return 1;
  }
  return -1;
};

/**
 * TODO once the values becomes available
 */
const temperatureComparator = null;

/**
 * TODO once the values becomes available
 */
const ticketsComparator = null;

export {
  nameComparator,
  statusComparator,
  autonomousComparator,
  batteryComparator,
  userComparator,
  estopComparator,
  localisationComparator,
  temperatureComparator,
  ticketsComparator
};
